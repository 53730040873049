/*========================
09. Icon Box Style CSS
=========================*/

.icon-box-item {
  display: flex;
  align-items: center;

  align-items: flex-start; // Aligns icon to the top

  @media #{$md-device} {
    align-items: flex-start; // Aligns icon to the top
    display: block;
  }

  @media #{$sm-device} {
    align-items: flex-start; // Aligns icon to the top
    margin-top: 40px;
  }

  .icon-box {
    &__icon {
      text-align: center;
      margin-right: 20px;
      flex-basis: 120px;
      width: 50px;

      @media #{$md-device} {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }

    &__info {
      flex-basis: calc(100% - 50px);
      width: calc(100% - 50px);

      @media #{$xl-device} {
        flex-basis: 215px;
        width: 215px;
      }

      @media #{$md-device} {
        flex-basis: 100%;
        width: 100%;
      }

      h5 {
        font-weight: 500;
        line-height: 1;
        margin-bottom: 10px;
      }
    }
  }
}