/*===========================
--. Footer Style CSS
===========================*/
.footer-area,.call-top-action-wrap {
  background-color: $offWhite;
}

.widget-item {
  margin-top: 38px;

  .widget-title {
    color: $brandColor;
    font-size: 22px;
    font-weight: 600;
    line-height: 1;
    margin-top: -1px;
    margin-bottom: 22px;

    @media #{$sm-device} {
      margin-bottom: 15px;
    }
  }

  address {
    line-height: 2;
    margin-bottom: 0;
    font-weight: 500;
  }
}

.widget-list {
  li {
    line-height: 2;

    a {
      color: $paragraph;
      font-weight: 500;
      transition: 0.2s;

      &:hover {
        color: $brandColor;
        padding-left: 5px;
      }
    }
  }
}

// About Widget
.about-widget {
  img {
    max-width: 120px;
    margin-bottom: 20px;
  }
}
/* Information Links Styling */
.footer-links {
  padding: 0;
}

.footer-links .widget-title {
  margin-bottom: 8px; /* Reduce space below title */
}

.footer-links .widget-list {
  padding: 0; /* Remove default padding */
  margin: 0;  /* Remove default margin */
}

.footer-links .widget-list li {
  margin-bottom: 4px; /* Reduce space between list items */
  list-style: none;    /* Remove bullet points */
}

.footer-links .widget-list li a {
  text-decoration: none; 
  color: inherit;
  display: inline-block;
  line-height: 1.2; /* Adjust line-height as needed */
}

/* Hover Effect (Optional) */
.footer-links .widget-list li a:hover {
  text-decoration: underline; /* Adds underline on hover */
  color: #007bff;             /* Changes color on hover */
}
.footer-middle-row {
  margin-top: 0px;
  margin-bottom: 0px;
  display: flex;
  justify-content: space-between; /* Evenly distribute images */
  align-items: center;            /* Vertically center images */
}

.footer-middle-row .col-md-4 {
  display: flex;
  justify-content: center; /* Center images within columns */
  padding: 0 10px;         /* Horizontal padding between images */
}

.footer-middle-row img {
  max-width: 100%;
  height: auto;
  display: block;
}

/* Responsive Adjustments */
@media (max-width: 767.98px) {
  .footer-middle-row {
      flex-direction: column; /* Stack images vertically on small screens */
  }

  .footer-middle-row .col-md-4 {
      padding: 10px 0; /* Vertical padding for stacked images */
  }
}

.copyright-txt {
  margin-top: 25px;
  font-weight: 500;

  @media #{$sm-device} {
    margin-top: 15px;
  }
}